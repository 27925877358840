@use "common";
@use "mixins";
@use "theme-variables" as theme;

/* stylelint-disable selector-no-qualifying-type */
[flyLink] {
  @include mixins.link();

  .fly-link-icon {
    width: 1em;
    height: 1em;
    margin-bottom: -.15em;
  }
}
/* stylelint-enable selector-no-qualifying-type */

.fly-button-size-small {
  padding: .125rem .25rem;
  height: 1.375rem;
}

.fly-button-size-large {
  padding: .5rem 1rem;
  height: 2.3125rem;
}

.fly-button-size-medium {
  padding: .25rem .75rem;
  height: 1.8125rem;

  &.fly-button-tertiary,
  &.fly-button-ghost,
  &.fly-button-dangerghost {
    padding: .25rem;
  }
}

.fly-button-size-large-icononly {
  padding: .5rem;
  height: 2.25rem;
  width: 2.25rem;
}

.fly-button-size-medium-icononly {
  padding: .25rem;
  height: 1.75rem;
  width: 1.75rem;
}

.fly-button-size-small-icononly {
  padding: .125rem;
  height: 1.3125rem;
  width: 1.3125rem;
}

.fly-button-size-map-icononly {
  padding: .25rem;
  height: 1.8125rem;
  width: 1.8125rem;
}

.fly-button-global {
  display: inline-flex;
  align-items: center;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(theme.$text-interactive);
  cursor: pointer;
  border: none;
  border-radius: .25rem;
  justify-content: center;
  white-space: nowrap;

  &:focus-visible {
    outline: var(theme.$focus) solid common.$spacing-01;
    outline-offset: -#{common.$spacing-01};
  }

  .icon {
    display: inherit;
    margin: common.$spacing-01 0;
    justify-content: center;
  }

  &:disabled .icon {
    color: var(theme.$disabled-03);
    cursor: default;
  }

  .icon-left {
    margin-right: .25rem;
  }

  .icon-right {
    margin-left: .25rem;
  }
}

.fly-button-primary {
  background: var(theme.$interactive-primary);

  &:hover {
    background: var(theme.$hover-primary);
  }

  &:active {
    background: var(theme.$press-primary);
  }

  &:disabled {
    color: var(theme.$disabled-03);
    background: var(theme.$disabled-02);
    cursor: default;
  }
}

.fly-button-secondary {
  background: var(theme.$interactive-secondary);

  &:hover {
    background: var(theme.$hover-secondary);
  }

  &:active {
    background: var(theme.$press-secondary);
  }

  &:disabled {
    color: var(theme.$disabled-03);
    background: var(theme.$disabled-02);
    cursor: default;
  }
}

.fly-button-tertiary {
  background: none;
  color: var(theme.$link-primary);

  &:hover {
    color: var(theme.$hover-link);
  }

  &:active {
    color: var(theme.$press-link);
  }

  &:disabled {
    color: var(theme.$disabled-02);
    background: none;
    cursor: default;
  }
}

@mixin fly-button-ghost-outline {
  outline: var(theme.$hover-border) solid .0625rem;
  outline-offset: -.0625rem;
}

.fly-button-ghost {
  background: none;

  &:hover {
    @include fly-button-ghost-outline();
    background: var(theme.$hover-ui);
  }

  &:active {
    @include fly-button-ghost-outline();
    background: var(theme.$press-ui);
  }

  &:disabled {
    color: var(theme.$disabled-02);
    background: none;
    cursor: default;
  }
}

.fly-button-danger {
  background: var(theme.$interactive-danger);

  &:hover {
    background: var(theme.$hover-danger);
  }

  &:active {
    background: var(theme.$press-danger);
  }

  &:disabled {
    color: var(theme.$disabled-03);
    background: var(theme.$disabled-02);
    cursor: default;
  }
}

.fly-button-dangerghost {
  color: var(theme.$interactive-danger-ghost);
  background: none;

  &:hover {
    background: var(theme.$hover-danger);
    color: var(theme.$focus);
  }

  &:active {
    background: var(theme.$press-danger);
  }

  &:disabled {
    color: var(theme.$disabled-02);
    background: none;
    cursor: default;
  }
}

.fly-button-map {
  background: var(theme.$interactive-map);

  &:hover {
    background: var(theme.$hover-map);
  }

  &:active {
    background: var(theme.$press-map);
  }

  &:disabled {
    color: var(theme.$disabled-02);
    background: var(theme.$disabled-map);
    cursor: default;
  }
}

.fly-button-card {
  @include mixins.fly-card($padding: common.$spacing-02 common.$spacing-05);
  display: flex;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    cursor: default;

    & * {
      opacity: .5;
    }
  }

  &.selected {
    border-left: common.$spacing-01 solid var(theme.$selected-primary);
  }
}

.fly-button-pill {
  display: flex;
  align-items: center;
  background: var(theme.$interactive-ui);
  padding: .25rem;
  gap: .25rem;
  border-radius: .5rem;
  cursor: pointer;

  &:hover {
    background: var(theme.$hover-ui);
  }
  &:focus {
    background: var(theme.$interactive-ui);
    box-shadow: 0 0 0 2px var(theme.$focus) inset;
  }
  &:active {
    background: var(theme.$press-ui);
  }
  &:disabled {
    pointer-events: none;
    background: none;
    color: var(theme.$disabled-02);
    cursor: default;
  }

  &.selected {
    background: var(theme.$selected-ui);
  }
}

// I just want to style myself differently based on where I am, but that's not allowed in angular, so global it is.
body > .fly-modal-open .fly-modal {
  max-height: 90vh;
  max-width: 80vw;
  width: max-content;
  height: max-content;

  &.mobile {
    max-height: 95vh;
    max-width: 95vw;
  }
}

.fly-card-selected {
  border-left: common.$spacing-01 solid var(theme.$selected-primary);
}

.fly-abstract-popout {
  cursor: pointer;
}
